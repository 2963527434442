<template>
  <div class="import-container">
    <import-template
      :uploadUrl="uploadUrl"
      :downloadUrl="downloadUrl"
      uploadLabel="更新二维码"
      :autoUpload="true"
      :submitUrl="importQrcodeURL"
      @onSave="onSave"
      >
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { uploadUrl, importQrcodeURL } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl,
      importQrcodeURL,
      downloadUrl: '/static/excel/更新二维码数据模板.xls'
    }
  },
  created () {
    this.$setBreadcrumb('导入')
  },
  methods: {
    previous () {
      this.$router.go(-1)
    },
    onSave (data) {
      if (data) {
        this.$axios.post(importQrcodeURL, data).then(res => {
          if (res.status === '100') {
            this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
            this.previous()
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .import-container {
  }
  .header {
    position: absolute;
    top: 20px;
    right: 20px;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
</style>
