var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c("import-template", {
        attrs: {
          uploadUrl: _vm.uploadUrl,
          downloadUrl: _vm.downloadUrl,
          uploadLabel: "更新二维码",
          autoUpload: true,
          submitUrl: _vm.importQrcodeURL,
        },
        on: { onSave: _vm.onSave },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }